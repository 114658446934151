import { useState, useEffect } from "react"
import { AuthContext } from "./Can"

/**
 * This component is contextprovider consist of
 * signup login states and displaydata.
 *  this is responsible for main output display among
 * (LogIn,SignUp,Congratulation,Main dashboard)
 * and aslo provide display data after log in.
 * @param   {props} param1 children (all components).
 * @return  {Component}  providercomponent to all
 * components of apps.
 */
const AuthProvider = (props) => {
   /**
    * This get login status.
    */

   /**
    * This get display data, provided at Auth.
    */
   const [userData, setuserData] = useState({ 
      first_name: JSON.parse(localStorage.getItem("user")) ? JSON.parse(localStorage.getItem("user"))[0]?.first_name : '',
       last_name: JSON.parse(localStorage.getItem("user")) ? JSON.parse(localStorage.getItem("user"))[0]?.last_name : '', 
       profile_pic_image_id:JSON.parse(localStorage.getItem("user")) ? JSON.parse(localStorage.getItem("user"))[0]?.profile_pic_image_id : '',
       cafe_name:JSON.parse(localStorage.getItem("user")) ? JSON.parse(localStorage.getItem("user"))[0]?.cafe_name : ''
       })

   const userDataSetter = (data) => {
      setuserData(data)
   }
   useEffect(() => {
      return () => {}
   }, [])

   return (
      <AuthContext.Provider value={[userData, userDataSetter]}>
         {props.children}
      </AuthContext.Provider>
   )
}
export default AuthProvider
