
import axios from "axios"
import { DefaultRoute } from "../router/routes"

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = (obj) => Object.keys(obj).length === 0
import toast from "react-hot-toast"
// ** Returns K format from a number
export const kFormatter = (num) => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

// logout
export const logout = () => {
  document.cookie = `token=;expires=${new Date(0).toUTCString()}`
  localStorage.removeItem('user')
  window.location.href = '/login'
  // window.location.reload(false)
}
// ** Converts HTML to string
export const htmlToString = (html) => html.replace(/<\/?[^>]+(>|$)/g, "")

// Reading Cookie
export function getCookieByName(cookiename) {
  // Get name followed by anything except a semicolon
  const cookiestring = RegExp(`${cookiename}=[^;]+`).exec(document.cookie)
  // Return everything after the equal sign, or an empty string if the cookie name not found
  return decodeURIComponent(!!cookiestring ? cookiestring.toString().replace(/^[^=]+./, "") : "")
  }

// ** Axios instance
export const instance = () => {
  const token = getCookieByName('token')
  if (!token) {
    logout()
  }
  return axios.create({
    baseURL:process.env.REACT_APP_BASE_API_URL,
    headers: { Authorization: `bearer ${token}` }
  })
}

export const instanceV1 = () => {
  const token = getCookieByName('token')
  if (!token) {
    logout()
  }
  return axios.create({
    baseURL: `${process.env.REACT_APP_BASE_API_URL}/api/v1`,
    headers: { Authorization: `bearer ${token}` }
  })
}

export const apiInstanceV1 = () => {
  const token = getCookieByName('token')
  if (!token) {
    logout()
  }
  return axios.create({
    baseURL: `${process.env.REACT_APP_BASE_API_URL}/api/v1`,
    headers: { Authorization: `bearer ${token}` }
  })
}
// ** Checks if the passed date is today
const isToday = (date) => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (
  value,
  formatting = { month: "short", day: "numeric", year: "numeric" }
) => {
  if (!value) return value
  return new Intl.DateTimeFormat("en-US", formatting).format(new Date(value))
}


// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = { month: "short", day: "numeric" }

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: "numeric", minute: "numeric" }
  }

  return new Intl.DateTimeFormat("en-US", formatting).format(new Date(value))
}

export const formatDateTime = (value) => {
  const formatting = { month: "short", day: "numeric", hour: "numeric", minute: "numeric" }
  return new Intl.DateTimeFormat("en-US", formatting).format(new Date(value))
}
export const formatTime = (value) => {
  const formatting = {hour12: false, hour: "numeric", minute: "numeric", second: "numeric"}
  return new Intl.DateTimeFormat("en-US", formatting).format(new Date(value))
}
export const format12Time = (value) => {
  // 12:13:15 to 12:13am
  if (Number(value.slice(0, 2)) < 13) {
    return  `${value.slice(0, 5)}am`
  } else {
    return  `${Number(value.slice(0, 2)) - 12}:${value.slice(3, 5)}pm`
  }
}
/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => localStorage.getItem("userData")
export const getUserData = () => JSON.parse(localStorage.getItem("user"))

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = (user) => {
  if (user) return DefaultRoute
  return "/login"
}

// Upload Image to Cloude Flare
export const uploadImage = async(file) => {
  const newInstance = instance()
  let img_id = ''
  try {
    const j = await newInstance.post('/uploadImage', {file}, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
      img_id = `${j.data.id}`
  } catch (err) {
        console.warn(err)
      }
    return img_id
}

export const handleApiError = (error) => {
  toast.dismiss()
  if (error.response) {
  if (error.response.status === 401) {
    logout()
  } else {
      if (error.response.data === undefined) {
        toast.error(error.message)
      } else {
        // console.log('Invalid token or expired!', error.response.data.msg.startsWith('Invalid token or expired!'))

        if (error.response.data.msg) {
          toast.error(error.response.data.msg)
        } else {
          error.message === 'Request failed with status code 500' ? toast.error(error.response.data) : toast.error('ERROR')
        }
  }
 }
} else { console.log(error) }

}
// Get image from cloud flare using image id
export const getImageCloudFlare = (image_id, quality = process.env.REACT_APP_CLOUD_IMAGE_Default_Extention) => {
  return `${process.env.REACT_APP_CLOUD_IMAGE_BASE_URL}/${image_id}/${quality}`
}
// ** React Select Theme Colors
export const selectThemeColors = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: "#7367f01a", // for option hover bg-color
    primary: "#7367f0", // for selected option bg-color
    neutral10: "rgba(0, 0, 0, 0.12)", // for tags bg-color
    neutral20: "#ededed", // for input border-color
    neutral30: "#ededed" // for input hover border-color
  }
})
