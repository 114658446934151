import { Card, Spinner, Modal, ModalHeader, Input, Form, Button, CardHeader, CardBody } from 'reactstrap'

import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import { ReactSelectStyle } from '../../Components/ReactSelect'
import { useState } from 'react'


const OrderDetailModal = (props) => {
    const { isOpen, payload, toggel, addItem } = props

    const [data, setData] = useState({
        ...payload,
        // itemId: payload.item.universal_menu_item_id,
        // comboItemId:payload.item.cafe_menu_combo_id,
        // itemName: payload.item.item_name,
        // itemPrice: payload.item.base_price,
        // foodType: payload.item.food_type,
        // isAddonCompulsory:payload.is_addon_compulsory,
        // isVariantCompulsory: payload.is_variant_compulsory,
        // addonOptions: payload.addonList,
        // variantOptions: payload.variantList,
        selectedAddon: payload.selectedAddon || [],
        selectedVarient: payload.selectedVarient || {},
        instruction: payload.instruction || '',
        quantity: payload.quantity || 0
    })

    const animatedComponents = makeAnimated()
    const handleToggel = () => {
        toggel()
    }

    return (
        <Modal isOpen={isOpen} centered toggle={handleToggel} >

            <ModalHeader toggle={handleToggel}  ></ModalHeader>
            <div style={{ padding: '2rem', paddingTop: '0' }} >
                <h1 className='text-center mb-1' >{payload.itemName}</h1>
                {data.variantOptions.length ? <div style={{ marginTop: '15px' }}>
                    <label className='label1'>Select Variants {data.isVariantCompulsory ? <span style={{ color: 'red' }}> * </span> : ''}</label>
                    <Select
                        styles={ReactSelectStyle}
                        closeMenuOnSelect={true}
                        value={data.selectedVarient}
                        onChange={(e) => setData((prev) => {
                            return {
                                ...prev,
                                selectedVarient: e
                            }
                        })}
                        components={animatedComponents}
                        options={data.variantOptions}
                        isDisabled={!data.variantOptions.length}
                    />
                </div> : <></>}
                {data.addonOptions.length ? <div style={{ marginTop: '15px' }}>
                    <label className='label1'>Select Addons {data.isAddonCompulsory ? <span style={{ color: 'red' }} > * </span> : ''}</label>
                    <Select
                        isMulti={true}
                        styles={ReactSelectStyle}
                        closeMenuOnSelect={true}
                        value={data.selectedAddon}
                        onChange={(e) => setData((prev) => {
                            return {
                                ...prev,
                                selectedAddon: e
                            }
                        })}
                        options={data.addonOptions}
                        components={animatedComponents}
                        isDisabled={!data.addonOptions.length}
                    />
                </div> : <></>}
                <div style={{ marginTop: '15px' }}>
                    <label className='label1'>Instructions</label>
                    <Input type="textarea" placeholder='Write here'
                        value={data.instruction || ""}
                        onChange={(e) => setData((prev) => {
                            return { ...prev, instruction: e.target.value }
                        })}
                        style={{ height: '100px' }} />
                </div>
                <div style={{ marginTop: '15px' }}>
                    <Button className='me-2' outline color="primary" onClick={handleToggel} >
                        Cancel
                    </Button>
                    <Button className="eat-btn" onClick={() => addItem({ ...data, quantity: data.quantity})}>
                        Add
                    </Button>
                </div>
            </div>


        </Modal >
    )
}

export default OrderDetailModal