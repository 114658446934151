import OneSignalReact from "react-onesignal"
import { handleApiError, instance } from "../Utils"

let asked = false

export const getDeviceType = () => {
  const ua = window.navigator.userAgent
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return "tablet"
  }
  if (
    /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua
    )
  ) {
    return "mobile"
  }
  return "desktop"
}


const getDeviceName = () => {
  let device = "Unknown"
  const ua = {
    "Generic Linux": /Linux/i,
    Android: /Android/i,
    BlackBerry: /BlackBerry/i,
    Bluebird: /EF500/i,
    "Chrome OS": /CrOS/i,
    Datalogic: /DL-AXIS/i,
    Honeywell: /CT50/i,
    iPad: /iPad/i,
    iPhone: /iPhone/i,
    iPod: /iPod/i,
    macOS: /Macintosh/i,
    Windows: /IEMobile|Windows/i,
    Zebra: /TC70|TC55/i
  }
  Object.keys(ua).map(v => window.navigator.userAgent.match(ua[v]) && (device = v))
  return device
}

export const runOneSignal = async () => {
  const newInstance = instance()
  if (asked) {
    return
  }
  // let connected = false
  try {
    asked = true
    if (Notification.permission === 'default') {

      Notification.requestPermission().then(async (permission) => {
        if (permission === 'granted') {
          console.log('granted')
          await OneSignalReact.init({
            appId:
              `${process.env.REACT_APP_ONESIGNAL_APP_ID}`,
            notifyButton: {
              enable: false
            }

          })
          await OneSignalReact.showSlidedownPrompt()
        } else if (Notification.permission === 'granted') {
          await OneSignalReact.init({
            appId:
              `${process.env.REACT_APP_ONESIGNAL_APP_ID}`,
            autoResubscribe: false,
            notifyButton: {
              enable: false
            }
          })
        }
      })
      // await OneSignalReact.showNativePrompt()
    }

    await OneSignalReact.getUserId(async (userId) => {
      const device_type = getDeviceType()
      const device_brand_name = getDeviceName()
      const data = {
        player_id: userId,
        device_type,
        device_brand_name
      }
      if (userId) {
        try {
          const j = await newInstance.post("/create-device-details", data)
          console.log(j)
        } catch (err) {
          console.log(err)
          handleApiError(err)
        }
      }
    })


    OneSignalReact.on('subscriptionChange', function (isSubscribed) {
      console.log("The user's subscription state is now:", isSubscribed)

      OneSignalReact.getUserId(async (userId) => {
        const device_type = getDeviceType()
        const device_brand_name = getDeviceName()
        const data = {
          player_id: userId,
          device_type,
          device_brand_name
        }
        console.log(data)
        try {
          const j = await newInstance.post("/create-device-details", data)
          console.log(j)
        } catch (err) {
          console.log(err)
          handleApiError(err)
        }
      })
    })
  } catch (error) {
    console.log(error)
  } finally {
    asked = false
  }
}