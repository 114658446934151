import { useRef, useEffect, useState } from "react"
import { ArrowLeft, ArrowRight, ChevronRight, MapPin, Minus, Plus } from "react-feather"
import { handleApiError, instanceV1 } from "../../../utility/Utils"
import { Spinner, Card, CardHeader, CardBody, CardFooter, Button, Modal, ModalHeader, ModalBody, Form, Input, Table, ButtonGroup } from "reactstrap"
import { NoInstructionIcon, NonVeg, Veg, Egg1, InstructionIcon, Pin, UnPin } from "../../../@core/assets/fonts/feather/Icons"
import OrderDetailModal from "./OrderDetailModal"
import Cart from "./Cart"
import toast from "react-hot-toast"
import { useNavigate } from "react-router-dom"

const Order = () => {
    const deivRef = useRef(null)
    const [searchString, setSearchString] = useState("")
    const [itemCategory, setItemCategory] = useState([])
    const [cart, setCart] = useState([])

    const [winWidth, setWinWidth] = useState(window.innerWidth)
    const navigate = useNavigate()
    const [active, setActive] = useState(0)
    const [menuItemContainerWidth, setMenuItemContainerWidth] = useState(deivRef?.current?.clientWidth)
    const [pinnedCategoryItem, setPinnedCategoryItem] = useState([])
    const [modalData, setModalData] = useState({
        modal: '',
        payload: {}
    })
    const handleClick = (id) => {
        const div = deivRef.current
        const elemient = div.querySelector(`[data-id="${id}"]`)
        const { top } = elemient.getBoundingClientRect()
        // const inView = top >= 0 && bottom <= window.innerHeight
        elemient.scrollIntoView({ top: top + div.screenY, behavior: "smooth", block: 'start' })
    }

    const getPinnedItems = (categoryList = itemCategory) => {
        const pinedItem = JSON.parse(localStorage.getItem("pinnedItem"))
        const pinned_item_ids = []
        const pinned_combos_ids = []
        const pinnedItemList = []
        const pinnedCombos = []
        const pinnedNornal = []

        for (const item of pinedItem) {
            if (item.cafe_menu_item_id) {
                pinned_item_ids.push(item.cafe_menu_item_id)
            }
            if (item.cafe_menu_combo_id) {
                pinned_combos_ids.push(item.cafe_menu_combo_id)
            }
        }
        for (let i = 0; i < categoryList.length; i++) {
            for (let j = 0; j < categoryList[i].foodItems.length; j++) {
                const combo = categoryList[i].foodItems[j].cafe_menu_combo_id
                const normal = categoryList[i].foodItems[j].cafe_menu_item_id
                if (combo && pinned_combos_ids.includes(combo)) {
                    categoryList[i].foodItems[j].isPinned = 1
                    if (!pinnedCombos.includes(combo)) {
                        pinnedItemList.push({ ...categoryList[i].foodItems[j], isPinned: 1 })
                        pinnedCombos.push(combo)
                    }
                } else if (normal && pinned_item_ids.includes(normal)) {
                    categoryList[i].foodItems[j].isPinned = 1
                    if (!pinnedNornal.includes(normal)) {
                        pinnedItemList.push({ ...categoryList[i].foodItems[j], isPinned: 1 })
                        pinnedNornal.push(normal)
                    }
                } else {
                    categoryList[i].foodItems[j].isPinned = 0
                }
            }
        }

        setPinnedCategoryItem({
            category_name: "Pinned",
            foodItems: [...pinnedItemList]
        })
        if (false) {
            const uniqueKeyMap = new Map(categoryList)

            for (const item of pinedItem) {
                const key = `${item.cafe_menu_item_id}-${item.cafe_menu_variant_id}-${item.status}-${item.cafe_menu_combo_id}-${item.cooking_instruction}-${JSON.stringify(item.orderAddOns.length)}`
                if (uniqueKeyMap.has(key)) {
                    const exixtingItem = uniqueKeyMap.get(key)
                    exixtingItem.count++
                    exixtingItem.order_detail_ids.push(item.cafe_order_item_id)
                    //   uniqueKeyMap.set(key, {...item, quantity:item.quantity || 1})
                } else {
                    item.count = 1
                    item.order_detail_ids = [item.cafe_order_item_id]
                    uniqueKeyMap.set(key, item)
                }
            }
            setPinnedCategoryItem(itemList)
        }

    }


    const handilPin = (toPin) => {
        const pinedItem = JSON.parse(localStorage.getItem("pinnedItem"))
        localStorage.setItem("pinnedItem", JSON.stringify([toPin, ...pinedItem]))
        getPinnedItems()
    }
    const handilUnPin = (toUnpin) => {
        const pinedItem = JSON.parse(localStorage.getItem("pinnedItem"))
        if (toUnpin.cafe_menu_item_id) {
            localStorage.setItem("pinnedItem", JSON.stringify(pinedItem.filter(e => e.cafe_menu_item_id !== toUnpin.cafe_menu_item_id)))
        } else if (toUnpin.cafe_menu_combo_id) {
            localStorage.setItem("pinnedItem", JSON.stringify(pinedItem.filter(e => e.cafe_menu_combo_id !== toUnpin.cafe_menu_combo_id)))
        }
        getPinnedItems()
    }
    const getActiveCategory = (e) => {
        const tar = e?.target?.childNodes || []
        let catc = true
        try {
            for (let i = 0; i < tar.length; i++) {
                const { top, bottom } = tar[i].getBoundingClientRect()
                // const inView = top >= 0 && bottom <= window.innerHeight
                // console.dir(document?.querySelector(`[data-id="d-${i}"]`))
                const inView = top < window.innerHeight && bottom >= (deivRef.current.clientHeight / 2)
                if (catc && inView) {
                    document?.querySelector(`[data-id="d-${i}"]`)?.classList.add('order-item-addons-active')
                    catc = false
                    for (let j = 0; j < tar.length; j++) {
                        if (j !== i) {
                            document?.querySelector(`[data-id="d-${j}"]`)?.classList.remove('order-item-addons-active')
                        }
                    }
                    break
                }
            }
        } catch {

        }
    }
    const SideBarCatagoryList = ({ dataId, title }) => {
        return <>
            <div data-id={`d-${dataId}`}
                className={`pt-1 ${dataId === 0 ? "order-item-addons-active" : ""}`}
                style={{ whiteSpace: "nowrap", display: 'flex', justifyContent: 'space-between', flexWrap: 'nowrap', cursor: 'pointer', alignItems: "center" }}
                onClick={() => handleClick(dataId)}>
                <div style={{ textWrap: "wrap", overflowWrap: "anywhere" }}>{title}</div><div><ChevronRight size={18} /></div>
            </div>
        </>
    }

    const getCombosCategoryList = async () => {
        const newInstance = instanceV1()
        try {
            const j = await newInstance.get('/combo-cat')
            // setItemCategory((prev) => [ ...j.data.allCats, ...prev])
            setItemCategory((prev) => [...prev, ...j.data.allCats])
        } catch (error) {
            handleApiError(error)
        }
    }
    const getNormalCategoryList = async () => {
        const newInstance = instanceV1()
        try {
            const j = await newInstance.get('/real-cat')
            setItemCategory((prev) => [...prev, ...j.data.allCats])
        } catch (error) {
            handleApiError(error)
        }
    }
    const getTagCategoryList = async () => {
        const newInstance = instanceV1()
        try {
            const j = await newInstance.get('/tags-cat')
            setItemCategory((prev) => [...j.data.allCats, ...prev])
            getPinnedItems(j.data.allCats)
            getActiveCategory()
        } catch (error) {
            handleApiError(error)
        }
    }
    const checkPinnedItems = () => {

        if (!localStorage.getItem("pinnedItem")) {
            localStorage.setItem("pinnedItem", JSON.stringify([]))
        }
    }

    useEffect(() => {
        checkPinnedItems()
        const resg = () => {
            setMenuItemContainerWidth(deivRef?.current?.clientWidth)
        }
        getTagCategoryList()
        getCombosCategoryList()
        getNormalCategoryList()
        deivRef?.current?.addEventListener('scroll', getActiveCategory)
        window.addEventListener('resize', (resg))
        resg()
        return () => {
            deivRef?.current?.removeEventListener('scroll', getActiveCategory)
            deivRef?.current?.addEventListener('resize', resg)
            setItemCategory([])
        }
    }, [])

    useEffect(() => {
        window.addEventListener('resize', () => {
            setWinWidth(window.innerWidth)
        })
        return () => window.removeEventListener('resize', () => {
            setWinWidth(window.innerWidth)
        })
    }, [])

    const ItemCards = () => {
        return <>
            <div ref={deivRef} className="menu-item-list" style={{ overflowY: 'scroll', maxHeight: "inherit" }}>
                {
                    [pinnedCategoryItem, ...itemCategory].filter((category) => (category.foodItems || []).find((item) => item.item_name.toLowerCase().includes(searchString.toLowerCase()))).map((e, index) => {
                        return (
                            <div key={index} data-id={index} >
                                <CardHeader className="category-title"> {e.category_name} </CardHeader>
                                <div className={menuItemContainerWidth > 800 ? "menu-item-container3" : menuItemContainerWidth > 540 ? "menu-item-container2" : "menu-item-container"}>
                                    {e.foodItems.map((ele, ind) => {
                                        return ele.item_name.toLowerCase().includes(searchString.toLowerCase()) && <Card className='menu-item-card' key={`item${ind}`}>

                                            <CardBody>
                                                <div >
                                                    <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'nowrap', paddingBottom: '0.5rem' }}>
                                                        <h4 style={{ fontSize: '0.9rem', alignItems: 'center', lineHeight: "1rem", fontWeight: '600' }}>{(ele.food_type === 1) ? <Veg size={15} /> : (ele.food_type === 2) ? <Egg1 size={15} /> : (ele.food_type === 0) ? <NonVeg size={15} /> : <></>}   {ele.item_name}</h4>
                                                        {!ele.isPinned ? <Pin
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() => {
                                                                handilPin({
                                                                    type: (ele.cafe_menu_combo_id ? "combo" : "normal"),
                                                                    cafe_menu_combo_id: ele.cafe_menu_combo_id,
                                                                    cafe_menu_item_id: ele.cafe_menu_item_id
                                                                })
                                                            }}
                                                        /> : <UnPin
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() => {
                                                                handilUnPin({
                                                                    type: (ele.cafe_menu_combo_id ? "combo" : "normal"),
                                                                    cafe_menu_combo_id: ele.cafe_menu_combo_id,
                                                                    cafe_menu_item_id: ele.cafe_menu_item_id
                                                                })
                                                            }}
                                                        />}
                                                    </div>
                                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', userSelect: 'none' }}>
                                                        <strong >₹ {ele.base_price || ele.price}</strong>
                                                        <div >
                                                            <div style={{ border: '1px solid #E6EAEE', borderRadius: '5px', padding: '0.6rem' }}>
                                                                <Minus style={{ cursor: 'pointer' }} size='15' onClick={() => {

                                                                    let cartCopy = [...cart]
                                                                    let found = false
                                                                    cartCopy.map((element, index) => {
                                                                        if (!found && element.itemId === ele.cafe_menu_item_id) {
                                                                            found = true
                                                                            if (element.quantity > 1) {
                                                                                cartCopy[index] = {
                                                                                    ...cartCopy[index],
                                                                                    quantity: cartCopy[index].quantity - 1
                                                                                }
                                                                            } else {
                                                                                cartCopy = [...cartCopy.slice(0, index), ...cartCopy.slice(index + 1, cartCopy.length)]
                                                                            }
                                                                        }
                                                                    })
                                                                    setCart(cartCopy)
                                                                }}
                                                                />
                                                                <span
                                                                    className="m-1">
                                                                    {
                                                                        cart.reduce((prev, current) => {
                                                                            if ((current.itemId && current.itemId === ele.cafe_menu_item_id) || (current.comboItemId && current.comboItemId === ele.cafe_menu_combo_id)) {
                                                                                return prev + current.quantity
                                                                            }
                                                                            return prev
                                                                        }, 0)
                                                                    }
                                                                </span>
                                                                <Plus style={{ cursor: 'pointer' }} size='15'
                                                                    onClick={() => {
                                                                        if (!ele.addon_list.length && !ele.menu_variant.length) {
                                                                            setCart((prev) => [
                                                                                {
                                                                                    itemId: ele.cafe_menu_item_id,
                                                                                    comboItemId: ele.cafe_menu_combo_id,
                                                                                    itemName: ele.item_name,
                                                                                    itemPrice: ele.base_price || ele.price,
                                                                                    foodType: ele.food_type,
                                                                                    isAddonCompulsory: ele.is_addon_compulsory,
                                                                                    isVariantCompulsory: ele.is_variant_compulsory,
                                                                                    addonOptions: [],
                                                                                    variantOptions: [],
                                                                                    selectedAddon: [],
                                                                                    selectedVarient: {},
                                                                                    instruction: '',
                                                                                    quantity: 1
                                                                                },
                                                                                ...prev
                                                                            ])
                                                                        } else {
                                                                            setModalData({
                                                                                modal: "orderDetail",
                                                                                payload: {
                                                                                    itemId: ele.cafe_menu_item_id,
                                                                                    comboItemId: ele.cafe_menu_combo_id,
                                                                                    itemName: ele.item_name,
                                                                                    itemPrice: ele.base_price || ele.price,
                                                                                    foodType: ele.food_type,
                                                                                    isAddonCompulsory: ele.is_addon_compulsory,
                                                                                    isVariantCompulsory: ele.is_variant_compulsory,
                                                                                    addonOptions: ele.addon_list.map((e) => {
                                                                                        return { label: e.addon_name, value: e.cafe_menu_addon_id, price: e.addon_price }
                                                                                    }),
                                                                                    variantOptions: ele.menu_variant.map((e) => {
                                                                                        return { label: e.variant_name, value: e.cafe_menu_variant_id, price: e.cafe_menu_variant_price }
                                                                                    })
                                                                                }
                                                                            })
                                                                        }

                                                                    }
                                                                    }
                                                                />
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </CardBody>

                                        </Card>

                                    })}

                                </div>

                            </div>

                        )
                    })
                }
            </div>
        </>
    }
    useEffect(() => {
        getPinnedItems()
    }, [itemCategory])

    return (
        <>
            {modalData.modal === 'orderDetail' && <OrderDetailModal
                isOpen={modalData.modal === 'orderDetail'}
                payload={modalData.payload}
                toggel={() => {
                    setModalData({ modal: '', payload: {} })
                }}
                addItem={(e) => {
                    if (e.isAddonCompulsory && e.selectedAddon.length === 0) {
                        toast.error("Addon is compulsory")
                    } else if (e.isVariantCompulsory && !e.selectedVarient.value) {
                        toast.error("Variant is compulsory")
                    } else {
                        setCart((prev) => [{ ...e, quantity: e.quantity + 1 }, ...prev])
                        setModalData({ modal: '', payload: {} })
                    }
                }}
            />}

            {/* //=========================================== */}
            <div style={{ height: 'calc(100vh - 115px)', display: "grid", gridTemplateRows: " auto 1fr", overflow: 'hidden' }}>
                <div style={{ display: `${(winWidth > 720) ? 'flex' : 'block'}`, justifyContent: 'space-between', marginBottom: '0.5rem', alignItems: "center" }}>
                    <h1 className="header700-2" style={{ cursor: 'pointer' }} onClick={() => navigate("/table-view")}><ArrowLeft size={21} /> Select Item</h1>
                    <div className="search">

                        <input className="input" placeholder="Search here" type="text" onChange={(e) => {
                            setSearchString(e.target.value)
                        }} />
                        <button>
                            <svg width="17" height="16" fill="none" xmlns="http://www.w3.org/2000/svg" role="img" aria-labelledby="search">
                                <path d="M7.667 12.667A5.333 5.333 0 107.667 2a5.333 5.333 0 000 10.667zM14.334 14l-2.9-2.9" stroke="currentColor" strokeWidth="1.333" strokeLinecap="round" strokeLinejoin="round"></path>
                            </svg>
                        </button>
                        <div>
                        </div>
                    </div>
                </div>
                {(winWidth > 720) ? <div style={{ display: "grid", gridTemplateColumns: "auto 275px", overflow: 'hidden', maxHeight: "calc(100vh - 165px)" }}>

                    <Card style={{ overflow: 'hidden', display: "grid", margin: '0', boxShadow: "none", gridTemplateColumns: "200px auto" }}>
                        <div style={{ borderRight: '1px solid #BFD1E3' }}>
                            {[pinnedCategoryItem, ...itemCategory].filter((category) => (category.foodItems || []).find((item) => item.item_name.toLowerCase().includes(searchString.toLowerCase()))).map((e, index) => <SideBarCatagoryList key={index} dataId={index} title={e.category_name} />)}
                        </div>
                        <div style={{ maxHeight: "calc( 100vh - 166px)" }} >
                            <ItemCards />
                        </div>
                    </Card>
                    <Cart
                        cart={cart}
                        changeCart={(e) => setCart(e)}
                        setModalData={(e) => setModalData(e)}
                    />
                </div> : <>
                    <div style={{ display: 'grid', gridTemplateRows: 'max-content 1fr', overflow: "hidden" }}>
                        <div className="d-flex">
                            <ButtonGroup className='order-btn-group'>
                                <Button className='no-wrap' onClick={() => setActive(() => 0)} active={active === 0}>
                                    Take Orders
                                </Button>
                                <Button className='no-wrap' onClick={() => setActive(() => 1)} active={active === 1}>
                                    Your Cart
                                </Button>
                            </ButtonGroup>
                        </div>
                        <div style={{ maxHeight: "calc( 100vh - 230px)", height: "calc( 100vh - 230px)" }}>
                            {active === 0 ? <ItemCards /> : <Cart
                                cart={cart}
                                changeCart={(e) => setCart(e)}
                                setModalData={(e) => setModalData(e)}
                            />}
                        </div>
                    </div>
                </>}
            </div>
        </>
    )
}

export default Order