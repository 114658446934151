// ** React Imports
import { Link } from "react-router-dom"
import {useEffect } from "react"

// ** Custom Components
import Avatar from "@components/avatar"

// ** Third Party Components
import {
  Power
} from "react-feather"

// ** Reactstrap Imports
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem
} from "reactstrap"
import { getCookieByName, getImageCloudFlare, logout } from "../../../../utility/Utils"
// import { AuthContext } from "../../../../utility/context/Can"

// ** Default Avatar Image
import defaultAvatar from "@src/assets/images/portrait/small/Waiter.png"
import { runOneSignal } from "../../../../utility/one-signal/OneSignal"
import { useDispatch, useSelector } from "react-redux"
import { GetUserDetail } from "../../../../redux/authrization"

const UserDropdown = () => {
  // const [userData, userDataSetter] = useContext(AuthContext)
  const dispatch = useDispatch()
    const profile = useSelector((state) => state.authrization.profile)
  const token = getCookieByName('token')

  useEffect(() => {
    if (token) {
      dispatch(GetUserDetail())
      runOneSignal()   
       }

    // if (false) {
    //    userDataSetter({
    //     first_name: JSON.parse(localStorage.getItem("user")) ? JSON.parse(localStorage.getItem("user"))[0].first_name : '',
    //     last_name: JSON.parse(localStorage.getItem("user")) ? JSON.parse(localStorage.getItem("user"))[0].last_name : '',
    //     profile_pic_image_id: JSON.parse(localStorage.getItem("user"))[0] ? JSON.parse(localStorage.getItem("user")).profile_pic_image_id : ''
    //    })
    // } 

    return () => {}
 }, [])
  return (
    <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
      <DropdownToggle
        href="/"
        tag="a"
        className="nav-link dropdown-user-link"
        onClick={(e) => e.preventDefault()}
      >
        <div style={{color:'black'}} className="user-nav d-sm-flex d-none">
          <span className="user-name fw-bold">
                  {`${profile.first_name || ""} ${profile.last_name || ""}`}
          </span>
          <span className="user-status">Captain</span>
        </div>
        <Avatar
          img={profile.profile_pic_image_id ? getImageCloudFlare(profile.profile_pic_image_id, process.env.REACT_APP_CLOUD_IMAGE_Avtar_Extention) : defaultAvatar}
          imgHeight="40"
          imgWidth="40"
          // status="online"
        />
      </DropdownToggle>
      <DropdownMenu end>
        <DropdownItem tag={Link} to="/login" onClick={() => logout()}>
          <Power size={14} className="me-75" />
          <span className="align-middle">Logout</span>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

export default UserDropdown
