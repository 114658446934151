// ** from react
import { useState} from 'react'  
import axios from 'axios'
import toast from 'react-hot-toast'
// ** Custom Components
import InputPasswordToggle from '@components/input-password-toggle'

// ** Reactstrap Imports
import { Card, CardBody, CardTitle, Form, Label,  Button, Spinner } from 'reactstrap'

// ** Styles
import '@styles/react/pages/page-authentication.scss'
import { useLocation, useNavigate } from 'react-router-dom'
import { EatsLogo } from '../../@core/assets/fonts/feather/Icons'

const OnboardPass = () => {

 const navigate = useNavigate()
 const loc = useLocation()
  const [input, setInput] = useState({
    newPassword:"",
    confirmPassword:""
  })
  const [loading, setLoading] = useState(false)

const inputHandler = (event) => {
      setInput({...input, [event.target.name]:event.target.value})
        
}
const handleSubmit = async() => {
  try {
    setLoading(true)
    await axios.put(`${process.env.REACT_APP_BASE_API_URL}/api/v1/change-password/${loc.search.split('token=')[1]}`, 
      {
        password: input.newPassword
      }
    )
    toast.success('Password Created! Login')
    navigate('/login')
  } catch (err) {
    if (err.response.data) {
      navigate('/not-authrize')
    } else {
      toast.error(err.message) 
    }
 } finally {
  setLoading(false)
 }
}
  return (
    <>
    
    <div className='auth-wrapper auth-basic px-2'>
      <div>
      <div style={{textAlign:"center", paddingBottom:"109px"}}>
        <EatsLogo/>
      </div>

      <div className='auth-inner my-2'>
        <Card className='mb-0'>
          <CardBody>
          <CardTitle tag='h4' className='m-2 text-center' >
            Create Password
            </CardTitle>
            <Form className='auth-login-form mt-2' onSubmit={e => e.preventDefault()}>
              
            <div className='mb-1'>
                <div className='d-flex justify-content-between'>
                  <Label className='form-label' for='login-password'>
                    Enter New Password
                  </Label>
                </div>
                <InputPasswordToggle value={input.newPassword} className='input-group-merge  mb-1' name='newPassword' onChange={inputHandler} />
                <div className='d-flex justify-content-between'>
                  <Label className='form-label' for='login-password'>
                    Re-Type Password
                  </Label>
                </div>
                <InputPasswordToggle  value={input.confirmPassword}  className='input-group-merge' name='confirmPassword'  onChange={inputHandler} />
                  
              </div>
              <Button style = {{background:"linear-gradient(166.45deg, #F2473F -14.38%, #FF6B34 105%)"}} 
                  disabled={!(input.newPassword.length > 6 && input.confirmPassword.length > 6 && input.newPassword === input.confirmPassword)}
                  onClick={handleSubmit}
                  block>
                Create Password {loading && <Spinner size='sm' />}
              </Button>
            </Form>
          </CardBody>
        </Card>
      </div>
      </div>
    </div>
    </>
  )
}

export default OnboardPass
