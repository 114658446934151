// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { handleApiError, instance } from "../utility/Utils"

export const GetUserDetail = createAsyncThunk("GetUserDetail", async () => {
    const newInstance = instance()
    let response = null
    try {
        response = await newInstance.get("/view-captain")
    } catch (error) {
        handleApiError(error)
    } finally {
        return response
    }
})

export const authSlice = createSlice({
    name: 'authentication',
    initialState: {
        profile: JSON.parse(window.localStorage.getItem('user'))?.profile || {},
        cafe_permission: JSON.parse(window.localStorage.getItem('user'))?.cafe_permission || {},
        api_version: JSON.parse(window.localStorage.getItem('user'))?.api_version || "",
        networkStatus: (navigator.onLine ? "online" : "offline"),
        isLoading: false
    },
    reducers: {
        updateUserData(state, action) {
            const {profile, cafe_permission, api_version} = action.payload
            if (profile) state.profile = profile
            if (cafe_permission) state.cafe_permission = cafe_permission
            if (api_version) state.api_version = api_version

            localStorage.setItem("user", JSON.stringify({
                profile:profile ?? state.profile,
                cafe_permission: cafe_permission ?? state.cafe_permission,
                api_version: api_version ?? state.api_version
            }))
        }
    },
    extraReducers: (builder) => {
        builder.addCase(GetUserDetail.fulfilled, (state, action) => {
            state.isLoading = false
            const permissions = action.payload.data?.cafe_permission?.length ? action.payload.data.cafe_permission[0] : {}
            const profile = action.payload.data?.getProfile?.length ? action.payload.data.getProfile[0] : {
                address_proof_id_url: '',
                email: "",
                first_name: '',
                last_name: '',
                mobile_number: null,
                photo_proof_id_url: '',
                profile_pic_image_id: '',
                user_admin_id: null
            }
            profile.cafe_name = permissions?.cafe_name
            state.profile = profile
            state.cafe_permission = permissions
            state.api_version = action.payload.data.api_version

            localStorage.setItem("user", JSON.stringify({
                profile,
                cafe_permission: permissions,
                api_version: action.payload.data.api_version
            }))
        })
        builder.addCase(GetUserDetail.rejected, (state) => {
            state.isLoading = false
        })
        builder.addCase(GetUserDetail.pending, (state) => {
            state.isLoading = true

        })
    }
})

export const { updateUserData} = authSlice.actions

export default authSlice.reducer
