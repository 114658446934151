import { Minus, Plus } from "react-feather"
import { Button, Card, CardBody, CardFooter, Spinner } from "reactstrap"
import { useState } from "react"
import { handleApiError, instanceV1 } from "../../../utility/Utils"
import toast from "react-hot-toast"
import { useNavigate, useParams } from "react-router-dom"
import OrderDetailModal from "./OrderDetailModal"
import { FoodType } from "../../Components/valueBasedComponent"

const Cart = ({ cart, changeCart }) => {
    const { id } = useParams()
    const navigate = useNavigate()
    const [ordering, setOrdering] = useState(false)
    const [currentIndex, setCurrentIndex] = useState()
    const [modalData, setModalData] = useState({
        modal: '',
        payload: {}
    })

    const placeOrder = async () => {
        const newInstance = instanceV1()
        const orders = []
        try {
            setOrdering(true)
            const cartCopy = [...cart]
            for (let i = 0; i < cartCopy.length; i++) {
                const item = { ...cartCopy[i] }
                for (let j = 0; j < item.quantity; j++) {
                    orders.push(
                        {
                            cafe_menu_item_id: item.selectedVarient?.value ? "" : item.itemId,
                            cafe_menu_variant_id: item.selectedVarient?.value ? item.selectedVarient?.value : "",
                            cafe_menu_combo_id: item.comboItemId,
                            is_addon: !!item.selectedAddon.length + 0,
                            cooking_instruction: item.instruction,
                            items: item.selectedAddon.map((e) => {
                                return {
                                    cafe_menu_addon_id: e.value
                                }
                            })
                        })
                }
            }
            await newInstance.post('/order-items', {
                cafe_order_list_id: id,
                itemss: orders
            })
            toast.success('Order Placed Successfully!', {
                duration: 4000,
                position: 'top-center',
                style: {
                    background: 'lightgreen',
                    color: '#fff'
                }
            })
            navigate('/take-order')
        } catch (error) {
            handleApiError(error)
        } finally {
            setOrdering(false)
        }
    }

    return <>
        {modalData.modal === 'orderDetail' && <OrderDetailModal
            isOpen={modalData.modal === 'orderDetail'}
            payload={modalData.payload}
            toggel={() => {
                setModalData({ modal: '', payload: {} })
            }}
            addItem={(e) => {
                const cartCopy = [...cart]
                if (e.isAddonCompulsory && e.selectedAddon.length === 0) {
                    toast.error("Addon is compulsory")
                } else if (e.isVariantCompulsory && !e.selectedVarient.value) {
                    toast.error("Variant is compulsory")
                } else {
                    cartCopy[currentIndex] = e
                    changeCart([...cartCopy])
                    setModalData({ modal: '', payload: {} })
                }
            }}
        />}
        <Card className="cart" style={{overflow:"hidden"}} >
            <div className="m-1 header">
                <h2 className=" fw-bolder" style={{ color: "#5E5873", fontSize: '1.125rem', lineHeight: '1.375rem', margin: '0' }}>
                    Your Cart
                </h2>
                <div style={{ background: '#FF3030', color: '#E7ECF0', border: '#FF3030', padding: '0.2rem 1rem', borderRadius: '20px', height: 'min-content' }} >
                {cart.reduce((p, n) => p + n.quantity, 0)}  Items
                </div>
            </div>

            {/* <hr /> */}
            <div style={{ overflow: 'overlay', flexGrow: '1' }}>
                <CardBody>
                    {cart.map((item, index) => {
                        return (
                            <div key={index} style={{ borderBottom: '1px solid #EDF5F9', paddingBottom: '1.25rem', paddingTop: '1.5rem' }} >
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div className="item-name"><FoodType value={item.foodType} size={13}/>
                                        <strong> {item.itemName}</strong>
                                    </div>
                                </div>
                                {item.selectedVarient.value ? <p className="varient-name">
                                    {item.selectedVarient.label}
                                </p> : <></>}
                                {item.selectedAddon.length > 0 && <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '0.25rem' }} >
                                    {(item.selectedAddon || []).map((addon, addonIndex) => <div key={addonIndex} className="addon-label">
                                        {addon.label}
                                    </div>
                                    )}

                                </div>}
                                {item.instruction && <div style={{ fontWeight: '400', fontSize: '0.75rem', lineHeight: '1.063rem', color: '#5B6D8E', marginTop: '0.3rem' }}>
                                    {item.instruction}
                                </div>}
                                {/* {item.addonOptions.length || item.variantOptions.length ? <div tag="button" className="variants-addons mt-1" onClick={() => {
                                    setCurrentIndex(index)
                                    setModalData({
                                        modal: "orderDetail",
                                        payload: item
                                    })
                                }}>
                                    <div>+</div>
                                    <div >Variants &amp; Addons</div>
                                </div> : <></>} */}
                                <div tag="button" className="variants-addons mt-1" onClick={() => {
                                    setCurrentIndex(index)
                                    setModalData({
                                        modal: "orderDetail",
                                        payload: item
                                    })
                                }}>
                                    <div>+</div>
                                    <div >Variants &amp; Addons</div>
                                </div>
                                <div style={{ display: "flex", justifyContent: 'space-between', alignItems: 'flex-end', marginTop: '0.78rem' }}>
                                    <div className="me-1" style={{ maxWidth: 'max-content', border: '1px solid #E6EAEE', borderRadius: '5px', padding: '0.348rem' }}>
                                        <Minus style={{ cursor: 'pointer' }} size='15' onClick={() => {
                                            let cartCopy = [...cart]
                                            if (item.quantity > 1) {
                                                cartCopy[index] = {
                                                    ...cartCopy[index],
                                                    quantity: cartCopy[index].quantity - 1
                                                }
                                            } else {
                                                cartCopy = [...cartCopy.slice(0, index), ...cartCopy.slice(index + 1, cartCopy.length)]
                                            }
                                            changeCart(cartCopy)
                                        }}
                                        />
                                        <span className="m-1"> {item.quantity}</span>
                                        <Plus style={{ cursor: 'pointer' }} size='15' onClick={() => {
                                            const cartCopy = [...cart]
                                            cartCopy[index] = {
                                                ...cartCopy[index],
                                                quantity: cartCopy[index].quantity + 1
                                            }
                                            changeCart(cartCopy)
                                        }} />
                                    </div>

                                    <strong style={{ float: 'right' }}> ₹ {(item.selectedVarient.price || item.itemPrice || 0) + item.selectedAddon.reduce((pre, current) => {
                                        return (pre + current.price)
                                    }, 0)}</strong>
                                </div>
                            </div>
                        )
                    })}

                </CardBody>
            </div>
            <CardFooter>
                <div>
                    <span>Total</span>
                    <strong style={{ float: 'right' }}>₹ {cart.reduce((p, n) => {
                        return p + ((n.quantity || 1) * ((n.selectedVarient?.value ? n.selectedVarient.price : n.itemPrice) + n.selectedAddon.reduce((ap, an) => {
                            return ap + an.price + 0
                        }, 0)))
                    }, 0)}</strong>
                </div>
                <Button className="eat-btn" onClick={placeOrder} style={{ width: '100%' }}
                    disabled={(Object.keys(cart).length === 0) || (ordering)}>Order {ordering &&
                        <Spinner size='sm' />}</Button>
            </CardFooter>
        </Card></>
}

export default Cart