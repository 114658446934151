import { Mail, Home, User, Disc } from "react-feather"

export default [
  { header : "MAIN Nav"},
  {
    id: "profile",
    title: "Profile",
    icon: <User size={20} />,
    navLink: "/profile"
  },
  { 
    header : "MENU",
    permissions:["allow_order"]
  },
  {
    id: "live-order",
    title: "Live Order",
    icon: <Disc size={20} />,
    navLink: "/live-order",
    permissions:["allow_order"]
  },
  
  {
    id: "take-order",
    title: "Take Order",
    icon: <Disc size={20} />,
    navLink: "/take-order",
    permissions:["allow_order"]
  }
]
