const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
  // [::1] is the IPv6 localhost address.
  window.location.hostname === "[::1]" ||
  // 127.0.0.1/8 is considered localhost for IPv4.
  window.location.hostname.match(
    /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
  )
)

export function register() {
  
  window.addEventListener("load", () => {
    // const swUrl = `${process.env.PUBLIC_URL}/sw.js`

    if (isLocalhost) {
      // This is running on localhost. Let's check if a service worker still exists or not.
      // checkValidServiceWorker(swUrl, config)
      console.log("Local host")
      navigator.serviceWorker.ready.then((registration) => {
        console.log(registration)
        console.log(
          "This web app is being served cache-first by a service " +
          "worker. To learn more, visit https://bit.ly/CRA-PWA"
        )
      })
    } else {
      if (!('serviceWorker' in navigator)) {
        console.log('Service Worker not supported')
      } else {
        console.log("SW supported")
        navigator.serviceWorker.register('/sw.js', {
          scope:'/'
        }).then(() => {
          console.log('SW registered')
        }).catch((err) => {
          console.log('SW failed to registor', err)
        })
      }
    }
  })
}

export function unregister() {
  console.log('unregestering sw.js')
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.ready.then((registration) => {
      registration.unregister()
    })
  }
}

