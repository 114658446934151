// ** React Imports
import { Navigate } from "react-router-dom"
// import { useContext, Suspense } from "react"
import { Suspense } from "react"
import { useSelector } from "react-redux"

const PrivateRoute = ({ children, route }) => {
  const cafePermissions = useSelector((state) => state.authrization.cafe_permission)
  const user = JSON.parse(localStorage.getItem("user"))

  if (route) {
    let restrictedRoute = false

    if (route.meta) {
      restrictedRoute = route.meta.restricted
    }
    if (!user) {
      return <Navigate to="/login" />
    }
    if (user && restrictedRoute) {
      return <Navigate to="/" />
    }
    if (route.permissions?.length) {
      for (const i of route.permissions) {
        if (!(cafePermissions || {})[i]) {
          return <Navigate to="/profile" />
        }
      }
    }
  }

  return <Suspense fallback={null}>{children}</Suspense>
}

export default PrivateRoute

// ** React Imports
// import { Navigate } from "react-router-dom"
// import { Suspense } from "react"
// import { getCookieByName } from "../../../utility/Utils"


// const PrivateRoute = ({ children, route }) => {
//   const userCred = getCookieByName('token')
//   const user = JSON.parse(localStorage.getItem("user")) 

//   if (route) {
//     let restrictedRoute = false

//     if (route.meta) {
//       restrictedRoute = route.meta.restricted
//     }
//     if (!userCred) {
//       localStorage.removeItem('user')
//     }
//     if (!user) {
//       return <Navigate to="/login" />
//     }
//     if (user && restrictedRoute) {
//       return <Navigate to="/" />
//     }
//   }

//   return <Suspense fallback={null}>{children}</Suspense>
// }

// export default PrivateRoute
